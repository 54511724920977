
import { defineComponent, ref } from 'vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import SelectInput from '@/components/atom/SelectInput.vue'
import TextInput from '@/components/atom/TextInput.vue'
import { Form } from 'vee-validate'
import FormWrapper from '@/components/molecules/Form.vue'
import * as yup from 'yup'
export default defineComponent({
  name: 'AddProject',
  components: {
    CustomButton,
    TextInput,
    Form,
    FormWrapper,
    SelectInput
  },
  setup() {
    const companySizeValues = [
      {
        label: '1 - 5',
        value: 'S1_5'
      },
      {
        label: '6 - 25',
        value: 'S6_25'
      },
      {
        label: '26 - 100',
        value: 'S26_100'
      },
      {
        label: '101+',
        value: 'S101_PLUS'
      }
    ]

    const schema = yup.object().shape({
      accountNumber: yup.string().required().label('Account number'),
      bankName: yup.string().required().label('Bank name'),
      bankAddress: yup.string().required().label('Bank address'),
      sortCode: yup.string().required().label('Sort code / BSB number'),
      vatNumber: yup.number(),
      VatPercentage: yup
        .number()
        .required()
        .label('Vat percentage')
        .typeError('VAT / GST percentage is required and must be number'),
      standardWorkHours: yup
        .number()
        .typeError('Standard work hours per day must be written as a whole number 8. Or as a decimal 7.5')
        .min(0)
        .required()
    })
    return { schema, companySizeValues }
  }
})
