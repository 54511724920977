
import { defineComponent } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import OverviewBlock from '@/components/atom/OverviewBlock.vue'
import TableTile from '@/components/templates/TableTile.vue'
import { intlFormat } from '@/utils/currencyFunctions'

export default defineComponent({
  name: 'ProjectDashboard',
  components: {
    OverviewBlock,
    DashboardSection,
    TableTile
  },
  setup() {
    return { intlFormat }
  }
})
