<template>
  <DashboardSection>
    <template #content>
      <div class="block-wrapper">
        <OverviewBlock>
          <template #heading> Team count </template>
          <template #score> {{ studioDetails?.user.length }} </template>
          <template #performance> </template>
        </OverviewBlock>
        <OverviewBlock>
          <template #heading> Monthly value </template>
          <template #score>{{ intlFormat(12100) }}</template>
        </OverviewBlock>
        <OverviewBlock>
          <template #heading> Total value </template>
          <template #score>{{ intlFormat(120000) }}</template>
        </OverviewBlock>
        <h3>Team Members</h3>
      </div>
      <UsersList v-if="studioDetails" :items="studioDetails" />
    </template>
  </DashboardSection>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import OverviewBlock from '@/components/atom/OverviewBlock.vue'
import { useStore } from '@/store'
import UsersList from '@/components/pages/superAdmin/studios/UsersList.vue'
import { intlFormat } from '@/utils/currencyFunctions'
export default defineComponent({
  name: 'ProjectDashboard',
  components: {
    OverviewBlock,
    DashboardSection,
    UsersList
  },
  setup() {
    const store = useStore()
    const studioDetails = computed(() => store.state.studio.studioDetails)
    return { studioDetails, intlFormat }
  }
})
</script>

<style lang="sass" scoped>
.block-wrapper
  width: 100%
  display: grid
  grid-template-columns: repeat(6, 1fr)
  justify-content: space-between
  grid-gap: 1rem
  @media screen and (max-width: $ms-breakpoint)
    grid-template-columns: 1fr
  .subtext
    color: $text-grey
    margin: 0.5rem
  h3
    margin-left: 30px
</style>
