
import { defineComponent, onMounted, ref } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import OverviewBlock from '@/components/atom/OverviewBlock.vue'
import TableTile from '@/components/templates/TableTile.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import TableLayout from '@/components/templates/TableLayout.vue'
import StatusIndicator from '@/components/atom/StatusIndicator.vue'
import authApi from '@/api/Authenticated'
import useDateFormatter from '@/utils/dateFormatter'
import type { studioTypes } from '@shared/'
import VueSimpleAlert from 'vue-simple-alert'
import { ActionTypes } from '@/store/messages/actions'
import { MutationTypes } from '@/store/messages/mutations'
import { useStore } from '@/store'

export default defineComponent({
  name: 'ProjectDashboard',
  components: {
    OverviewBlock,
    DashboardSection,
    TableTile,
    CustomButton,
    TableLayout,
    StatusIndicator
  },
  setup() {
    const store = useStore()
    const studios = ref<studioTypes.StudioWithDetails[]>([])
    const { dateFormatter } = useDateFormatter()
    async function getStudioDetails() {
      const res = await authApi.getAllStudios()
      studios.value = res
    }
    onMounted(getStudioDetails)

    const deleteStudio = async (studioId: number) => {
      VueSimpleAlert.confirm(
        'All data recorded in relation to this Studio will be deleted. Please confirm to continue.'
      ).then(async (res) => {
        if (res) {
          await authApi
            .deleteStudio(+studioId)
            .then(function () {
              const index = studios.value.findIndex((e) => e.id === studioId)
              if (index !== -1) {
                studios.value.splice(index, 1)
              }
            })
            .catch((error) => {
              store.commit(MutationTypes.SET_TOASTER_MESSAGE, error.response.data)
              store.dispatch(ActionTypes.SET_TOASTER_TYPE, 'error')
              store.dispatch(ActionTypes.SHOW_TOASTER_MESSAGE, true)
            })
        }
      })
    }

    return { studios, dateFormatter, deleteStudio }
  }
})
