
import { defineComponent, computed } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import OverviewBlock from '@/components/atom/OverviewBlock.vue'
import { useStore } from '@/store'
import UsersList from '@/components/pages/superAdmin/studios/UsersList.vue'
import { intlFormat } from '@/utils/currencyFunctions'
export default defineComponent({
  name: 'ProjectDashboard',
  components: {
    OverviewBlock,
    DashboardSection,
    UsersList
  },
  setup() {
    const store = useStore()
    const studioDetails = computed(() => store.state.studio.studioDetails)
    return { studioDetails, intlFormat }
  }
})
