<template>
  <DashboardSection>
    <template #content>
      <div class="block-wrapper">
        <CustomButton label="Export user data" color="grey" size="small" :iconVisible="false" />
        <CustomButton label="Anonymise user data" color="grey" size="small" :iconVisible="false" />
      </div>
      <UsersList v-if="studioDetails" :items="studioDetails" />
    </template>
  </DashboardSection>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import { useStore } from '@/store'
import UsersList from '@/components/pages/superAdmin/studios/UsersList.vue'
export default defineComponent({
  name: 'ProjectDashboard',
  components: {
    DashboardSection,
    CustomButton,
    UsersList
  },
  setup() {
    const store = useStore()
    const studioDetails = computed(() => store.state.studio.studioDetails)
    return { studioDetails }
  }
})
</script>

<style lang="sass" scoped>
.block-wrapper
  width: 100%
  display: grid
  grid-template-columns: repeat(6, 1fr)
  justify-content: space-between
  grid-gap: 1rem
  padding-bottom: 2rem
  @media screen and (max-width: $ms-breakpoint)
    grid-template-columns: 1fr
  .subtext
    color: $text-grey
    margin: 0.5rem
  h3
    margin-left: 30px
</style>
