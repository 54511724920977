
import { defineComponent, computed, onMounted } from 'vue'
import Tile from '@/components/atom/Tile.vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import { useStore } from '@/store'
import { ActionTypes } from '@/store/messages/actions'
import { MutationTypes } from '@/store/messages/mutations'
import { MutationTypes as ClientMutations } from '@/store/clients/mutations'
import { ActionTypes as ClientActionTypes } from '@/store/clients/actions'
import NoResult from '@/components/molecules/NoResult.vue'
import authApi from '@/api/Authenticated'
import { useRoute } from 'vue-router'
export default defineComponent({
  components: {
    Tile,
    DashboardSection,
    CustomButton,
    NoResult
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const studioId = route.params.studioId
    const isArch = computed(() => route.path.indexOf('archived') > -1)

    const getClients = async () => {
      await store.dispatch(ClientActionTypes.GET_CLIENTS, studioId)
    }
    const clients = computed(() => (isArch.value ? store.getters.archiveClients : store.getters.activeClients))
    onMounted(getClients)
    const getProjects = (projects: any) => {
      return projects.filter((item: any) => (isArch.value ? item.isArchived : !item.isArchived)).length
    }
    async function deleteClient(id: number) {
      const clientIndex = clients.value.findIndex((e: any) => e.id === id && e._count.Project <= 0)
      if (clientIndex !== -1) {
        await authApi
          .deleteClient(id)
          .then(function () {
            store.commit(ClientMutations.DELETE_CLIENT, +id)
          })
          .catch((error) => {
            store.commit(MutationTypes.SET_ERROR_MESSAGE, error.response.data)
            store.dispatch(ActionTypes.SHOW_ERROR_MESSAGE, true)
          })
      } else {
        store.commit(MutationTypes.SET_ERROR_MESSAGE, "You can't delete a client with 1 or more projects")
        store.dispatch(ActionTypes.SHOW_ERROR_MESSAGE, true)
      }
    }

    async function archiveClient(id: number) {
      await authApi.archiveUnarchiveClient(id)
      await store.dispatch(ClientActionTypes.GET_CLIENTS)
    }
    return { clients, deleteClient, studioId, archiveClient, isArch, getProjects }
  }
})
