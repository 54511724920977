<template>
  <TableLayout>
    <template #left-headings>
      <p class="left-heading-item">Name</p>
      <p class="left-heading-item">Role</p>
      <p class="left-heading-item">Since</p>
    </template>
    <template #right-headings>
      <p class="right-heading-item">Status</p>
    </template>
    <TableTile v-for="user of items?.user" :key="user.id">
      <template #tileContent>
        <div class="left-border-active custom-class-margin" :to="{ name: 'Overview', params: { id: user.id } }">
          <div class="left-content">
            <div>{{ user.fullName }}</div>
            <div>{{ user.role }}</div>
            <div>{{ dateFormatter(user.createdAt) }}</div>
          </div>
          <div class="right-content">
            <StatusIndicator class="margin-right" color="green" text="Active" />
          </div>
        </div>
      </template>
      <template #menu>
        <ul>
          <li>Delete</li>
        </ul>
      </template>
    </TableTile>
  </TableLayout>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import TableTile from '@/components/templates/TableTile.vue'
import TableLayout from '@/components/templates/TableLayout.vue'
import StatusIndicator from '@/components/atom/StatusIndicator.vue'
import useDateFormatter from '@/utils/dateFormatter'
import { studioTypes } from '@shared/'
export default defineComponent({
  components: {
    TableTile,
    TableLayout,
    StatusIndicator
  },
  props: {
    items: {
      type: Object as PropType<studioTypes.StudioWithDetails>,
      required: true
    }
  },
  setup() {
    const { dateFormatter } = useDateFormatter()
    return {
      dateFormatter
    }
  }
})
</script>

<style lang="sass" scoped>
$leftWidth: 10rem
$rightWidth: 5rem
.left-content, .right-content
  display: flex
  align-items: center
.left-content > *
  width: $leftWidth
.right-content > *
  min-width: $rightWidth
.right-heading-item
  min-width: $rightWidth
.left-heading-item
  min-width: $leftWidth
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
.mobile-hide
  @media( max-width: $md-breakpoint)
    display: none
.left-border-active
  border-left: 4px solid
  border-radius: 4px
.mobile-view
  display: none
  @media( max-width: $md-breakpoint)
    display: block
.custom-class-margin
  margin-bottom: unset
  border-bottom: 0.1px solid rgba(0, 0, 0, 0.1)
.margin-right
  margin-right: 0.5rem
</style>
