
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    color: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
})
