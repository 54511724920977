<template>
  <div class="teams">
    <Form @submit="submit" :validation-schema="schema" :initial-values="initialValues">
      <FormWrapper>
        <template #form-head>
          <h2>Add a new studio</h2>
        </template>
        <TextInput label="Studio name" inputType="text" name="studioName" placeholderText="Studio name" />
        <TextInput label="Team member name" inputType="text" name="userFullName" placeholderText="Full name" />
        <TextInput label="Their email" inputType="text" name="userEmail" placeholderText="Email" />
        <SelectInput
          label="Select currency code"
          selectLabel="Select currency"
          name="studioCurrencyCode"
          :items="currencyCodes"
        ></SelectInput>
        <template #footer>
          <CustomButton color="grey" :iconVisible="false" @onClick="$router.go(-1)" label="Cancel" />
          <CustomButton type="submit" color="blue" :label="'Set up team member'" />
        </template>
      </FormWrapper>
    </Form>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue'
import { Form } from 'vee-validate'
import { useStore } from '@/store'
import FormWrapper from '@/components/molecules/Form.vue'
import TextInput from '@/components/atom/TextInput.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import { studioTypes } from '@shared/'
import authApi from '@/api/Authenticated'
import { useRouter } from 'vue-router'
import * as yup from 'yup'
import { ActionTypes } from '@/store/messages/actions'
import { MutationTypes } from '@/store/messages/mutations'
import { currencies } from '@/utils/currencyFunctions'
import SelectInput from '@/components/atom/SelectInput.vue'

export default defineComponent({
  components: {
    Form,
    FormWrapper,
    TextInput,
    CustomButton,
    SelectInput
  },
  setup() {
    const currencyCodes = Object.values(currencies).map((item: any) => {
      return {
        value: item.code,
        label: item.code
      }
    })
    const router = useRouter()
    const store = useStore()
    const schema = yup.object({
      userEmail: yup.string().required().email(),
      userFullName: yup.string().required(),
      studioName: yup.string().required()
    })

    const addInitialValues: studioTypes.RegisterAdminStudio = {
      userEmail: '',
      userFullName: '',
      studioName: '',
      role: 'ADMIN',
      studioCountryCode: 'UK',
      studioSize: 'S1_5',
      studioCurrencyCode: 'GBP'
    }

    let formData: studioTypes.RegisterAdminStudio & {
      role: string
      studioCountryCode: string
      studioSize: string
      studioCurrencyCode: string
    } = {
      ...addInitialValues
    }

    const initialValues = ref(addInitialValues)
    async function submit(values: studioTypes.RegisterAdminStudio) {
      formData = { ...formData, ...values }
      authApi
        .registerAdminStudio(formData)
        .then((res) => {
          if (res !== undefined) {
            router.push({ name: 'AllStudios' })
            store.dispatch(MutationTypes.SET_TOASTER_MESSAGE, 'Studio added successfully')
            store.dispatch(ActionTypes.SET_TOASTER_TYPE, 'success')
            store.dispatch(ActionTypes.SHOW_TOASTER_MESSAGE, true)
          }
        })
        .catch((error) => {
          store.dispatch(MutationTypes.SET_TOASTER_MESSAGE, error.response.data)
          store.dispatch(ActionTypes.SET_TOASTER_TYPE, 'error')
          store.dispatch(ActionTypes.SHOW_TOASTER_MESSAGE, true)
        })
    }
    return { initialValues, submit, schema, formData, currencyCodes }
  }
})
</script>
<style lang="sass" scoped>
.teams
  display: flex
  width: 100%
  margin: 0 auto
  justify-content: center
.header-logo
  margin: 2rem
  height: 0.875rem
  align-self: flex-start
</style>
