<template>
  <div class="status" :class="color">{{ text }}</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    color: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
})
</script>

<style lang="sass" scoped>
.status
  padding: 0.2rem
  border-radius: 2px
  text-align: center
.green
  color: $white
  background-color: $green
.red
  color: $white
  background-color: $red
</style>
