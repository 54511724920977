
import { defineComponent, PropType } from 'vue'
import TableTile from '@/components/templates/TableTile.vue'
import TableLayout from '@/components/templates/TableLayout.vue'
import StatusIndicator from '@/components/atom/StatusIndicator.vue'
import useDateFormatter from '@/utils/dateFormatter'
import { studioTypes } from '@shared/'
export default defineComponent({
  components: {
    TableTile,
    TableLayout,
    StatusIndicator
  },
  props: {
    items: {
      type: Object as PropType<studioTypes.StudioWithDetails>,
      required: true
    }
  },
  setup() {
    const { dateFormatter } = useDateFormatter()
    return {
      dateFormatter
    }
  }
})
