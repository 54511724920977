
import Tabs from '@/components/atom/Tabs.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Home',
  components: {
    Tabs
  },
  setup() {
    const tabs = [
      { routerName: 'AllStudios', routerText: 'All Studios' },
      { routerName: 'Unsubscribed', routerText: 'Unsubscribed' },
      { routerName: 'Pipeline', routerText: 'Pipeline' }
    ]
    return { tabs }
  }
})
