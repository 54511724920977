<template>
  <div class="wrapper">
    <Form>
      <FormWrapper>
        <template #form-head>
          <h2>Edit studio details</h2>
        </template>
        <TextInput name="name" type="text" placeholderText="Name" label="Studio Name" />
        <SelectInput
          label="How many employees do you have?"
          selectLabel="Select number of employees"
          name="size"
          :items="companySizeValues"
        ></SelectInput>
        <TextInput name="address" type="text" placeholderText="Registered Address" label="Your studio address" />
        <TextInput name="mailAddress" type="text" placeholderText="Mailing Address" label="Your mailing address" />
        <TextInput
          type="number"
          name="standardWorkHours"
          placeholderText="Work hours per day"
          label="Standard work hours per day"
        />
        <h3>Financial details</h3>
        <TextInput name="accountNumber" type="text" placeholderText="A/C no." label="Your account number?" />
        <TextInput name="bankName" type="text" placeholderText="Bank name" />
        <TextInput name="bankAddress" type="text" placeholderText="Bank address" />
        <TextInput name="sortCode" type="text" placeholderText="Sort code / BSB number" />
        <TextInput name="bic" type="text" placeholderText="BIC (optional)" />
        <TextInput name="iban" type="text" placeholderText="IBAN (optional)" />
        <TextInput name="vatNumber" type="number" placeholderText="VAT Number (if applicable)" />
        <TextInput name="VatPercentage" type="number" placeholderText="VAT / GST percentage" />
        <template #footer>
          <CustomButton color="grey" :iconVisible="false" label="Cancel" />
          <CustomButton type="submit" label="Update details" color="blue" />
        </template>
      </FormWrapper>
    </Form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import SelectInput from '@/components/atom/SelectInput.vue'
import TextInput from '@/components/atom/TextInput.vue'
import { Form } from 'vee-validate'
import FormWrapper from '@/components/molecules/Form.vue'
import * as yup from 'yup'
export default defineComponent({
  name: 'AddProject',
  components: {
    CustomButton,
    TextInput,
    Form,
    FormWrapper,
    SelectInput
  },
  setup() {
    const companySizeValues = [
      {
        label: '1 - 5',
        value: 'S1_5'
      },
      {
        label: '6 - 25',
        value: 'S6_25'
      },
      {
        label: '26 - 100',
        value: 'S26_100'
      },
      {
        label: '101+',
        value: 'S101_PLUS'
      }
    ]

    const schema = yup.object().shape({
      accountNumber: yup.string().required().label('Account number'),
      bankName: yup.string().required().label('Bank name'),
      bankAddress: yup.string().required().label('Bank address'),
      sortCode: yup.string().required().label('Sort code / BSB number'),
      vatNumber: yup.number(),
      VatPercentage: yup
        .number()
        .required()
        .label('Vat percentage')
        .typeError('VAT / GST percentage is required and must be number'),
      standardWorkHours: yup
        .number()
        .typeError('Standard work hours per day must be written as a whole number 8. Or as a decimal 7.5')
        .min(0)
        .required()
    })
    return { schema, companySizeValues }
  }
})
</script>

<style lang="sass" scoped>
.wrapper
  padding-bottom: 90px
  display: flex
  width: 100%
  margin: 0 auto
  justify-content: center
</style>
