<template>
  <div class="clients-wrapper" v-if="clients && clients.length > 0">
    <DashboardSection>
      <template #top-right-button v-if="$route.name !== 'ArchiveClients'">
        <CustomButton
          iconClass="plus"
          @onClick="$router.push({ name: studioId ? 'AddAdminClient' : 'AddClient' })"
          label="New client"
          color="grey"
          size="small"
        />
      </template>
      <template #content>
        <div class="clients-row">
          <Tile v-for="item in clients" v-bind:key="item" class="client-tile">
            <template #right-section>
              <div :class="{ archive: $route.name == 'ArchiveClients' }" class="label">
                {{ $route.name == 'ArchiveClients' ? 'Archive' : 'Active' }}
              </div>
            </template>
            <template #title>
              <router-link
                :to="{
                  name: studioId ? 'clientDetailsAdmin' : 'ClientProjects',
                  params: studioId ? { studioId: studioId, id: item.id } : { id: item.id }
                }"
              >
                <span class="title">{{ item.name }}</span>
              </router-link>
            </template>
            <template #desc>
              <p class="secondary-values">{{ getProjects(item['Project']) }} Projects</p>
            </template>
            <template #menu>
              <ul>
                <li
                  @click="$router.push({ name: studioId ? 'EditAdminClient' : 'EditClient', params: { id: item.id } })"
                >
                  Edit
                </li>
                <li v-if="$route.name == 'ClientsListings'" @click="archiveClient(item.id)">Archive</li>
                <li v-if="$route.name == 'ArchiveClients'" @click="archiveClient(item.id)">Unarchive</li>
                <li @click="deleteClient(item.id)">Delete</li>
              </ul>
            </template>
          </Tile>
        </div>
      </template>
    </DashboardSection>
  </div>
  <div class="no-documents" v-else>
    <NoResult title="You haven't added any clients yet">
      <CustomButton
        v-if="!isArch"
        @click="$router.push({ name: studioId ? 'AddAdminClient' : 'AddClient' })"
        label="Add a new client"
        color="blue"
      />
    </NoResult>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue'
import Tile from '@/components/atom/Tile.vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import { useStore } from '@/store'
import { ActionTypes } from '@/store/messages/actions'
import { MutationTypes } from '@/store/messages/mutations'
import { MutationTypes as ClientMutations } from '@/store/clients/mutations'
import { ActionTypes as ClientActionTypes } from '@/store/clients/actions'
import NoResult from '@/components/molecules/NoResult.vue'
import authApi from '@/api/Authenticated'
import { useRoute } from 'vue-router'
export default defineComponent({
  components: {
    Tile,
    DashboardSection,
    CustomButton,
    NoResult
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const studioId = route.params.studioId
    const isArch = computed(() => route.path.indexOf('archived') > -1)

    const getClients = async () => {
      await store.dispatch(ClientActionTypes.GET_CLIENTS, studioId)
    }
    const clients = computed(() => (isArch.value ? store.getters.archiveClients : store.getters.activeClients))
    onMounted(getClients)
    const getProjects = (projects: any) => {
      return projects.filter((item: any) => (isArch.value ? item.isArchived : !item.isArchived)).length
    }
    async function deleteClient(id: number) {
      const clientIndex = clients.value.findIndex((e: any) => e.id === id && e._count.Project <= 0)
      if (clientIndex !== -1) {
        await authApi
          .deleteClient(id)
          .then(function () {
            store.commit(ClientMutations.DELETE_CLIENT, +id)
          })
          .catch((error) => {
            store.commit(MutationTypes.SET_ERROR_MESSAGE, error.response.data)
            store.dispatch(ActionTypes.SHOW_ERROR_MESSAGE, true)
          })
      } else {
        store.commit(MutationTypes.SET_ERROR_MESSAGE, "You can't delete a client with 1 or more projects")
        store.dispatch(ActionTypes.SHOW_ERROR_MESSAGE, true)
      }
    }

    async function archiveClient(id: number) {
      await authApi.archiveUnarchiveClient(id)
      await store.dispatch(ClientActionTypes.GET_CLIENTS)
    }
    return { clients, deleteClient, studioId, archiveClient, isArch, getProjects }
  }
})
</script>
<style lang="sass" scoped>
.clients-row
  display: flex
  flex-wrap: wrap
  & > *
    margin-right: 1rem
    margin-bottom: 1rem
.client-tile
  width: 45%
  a
    text-decoration: none
  @media( max-width: $ms-breakpoint)
    width: 100%
  @media( max-width: $md-breakpoint)
    width: 100%
.label
  padding: 0.3rem
  border-radius: 2px
  background-color: $purple-secondary
  color: $purple
  font-weight: 700
  font-size: 14px
.archive
  background-color: $mustard
  color: $white
.title
  font-size: 16px
  font-weight: 700
.secondary-values
  font-size: 12px
.count-view
  background-color: $mustard
  color: $white
  padding: 0.2rem 0.5rem
  border-radius: 5px
  width: fit-content
</style>
