
import { defineComponent, computed } from 'vue'
import ProjectList from '@/components/organisms/ProjectList.vue'
import VueSimpleAlert from 'vue-simple-alert'
import authApi from '@/api/Authenticated'
import { useStore } from '@/store'
export default defineComponent({
  name: 'ProjectsListing',
  components: {
    ProjectList
  },
  setup() {
    const store = useStore()
    const projects = computed(() => store.state.project.projects)

    const deleteProject = async (projectId: number) => {
      VueSimpleAlert.confirm(
        'All data recorded in relation to this Project will be deleted. Please confirm to continue.'
      ).then(async (res) => {
        if (res) {
          const deleteProject = await authApi.deleteProject(+projectId)
          if (deleteProject) {
            const index = projects.value.findIndex((e) => e.id === projectId)
            if (index !== -1) {
              projects.value.splice(index, 1)
            }
          }
        }
      })
    }
    return { projects, deleteProject }
  }
})
