
import { defineComponent, computed } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import { useStore } from '@/store'
import UsersList from '@/components/pages/superAdmin/studios/UsersList.vue'
export default defineComponent({
  name: 'ProjectDashboard',
  components: {
    DashboardSection,
    CustomButton,
    UsersList
  },
  setup() {
    const store = useStore()
    const studioDetails = computed(() => store.state.studio.studioDetails)
    return { studioDetails }
  }
})
