
import Tabs from '@/components/atom/Tabs.vue'
import { defineComponent, computed, onMounted } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import { ActionTypes as studioActions } from '@/store/studio/actions'
export default defineComponent({
  name: 'Home',
  components: {
    Tabs
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    onMounted(() => {
      store.dispatch(studioActions.GET_STUDIO_DETAILS_BY_ID, +route.params.studioId)
    })
    const studioDetails = computed(() => store.state.studio.studioDetails)
    const tabs = [
      { routerName: 'Overview', routerText: 'Overview' },
      { routerName: 'Billing', routerText: 'Billing' },
      { routerName: 'AdminTeam', routerText: 'Team' },
      { routerName: 'AdminProjects', routerText: 'Projects' },
      { routerName: 'AdminClients', routerText: 'Clients' }
    ]
    // store.dispatch(UserActionTypes.GET_USERS)
    return { tabs, studioDetails }
  }
})
