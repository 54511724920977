<template>
  <DashboardSection>
    <template #content>
      <div class="block-wrapper">
        <CustomButton
          @onClick="$router.push({ name: 'AddStudio' })"
          iconClass="plus"
          label="Add new"
          color="grey"
          size="small"
        />
      </div>
      <div class="block-wrapper">
        <OverviewBlock>
          <template #heading> Total studios </template>
          <template #score> {{ studios?.length }} </template>
          <template #performance>
            <!-- <p class="avg"><span class="arrow-up"></span>10%</p>
            <span>30 day rolling avg.</span> -->
          </template>
        </OverviewBlock>
        <OverviewBlock>
          <template #heading> New studios this Week </template>
          <template #score> 3 </template>
          <template #performance>
            <p class="avg"><span class="arrow-down"></span>33%</p>
            <span>30 day rolling avg.</span>
          </template>
        </OverviewBlock>
        <OverviewBlock>
          <template #heading> Retention </template>
          <template #score> 67 %</template>
          <template #performance>
            <p class="avg"><span class="arrow-up"></span>1%</p>
            <span>30 day rolling avg.</span>
          </template>
        </OverviewBlock>
        <h3>Studios</h3>
      </div>
      <TableLayout>
        <template #left-headings>
          <p class="left-heading-item">Studio name</p>
          <p class="left-heading-item">Since</p>
          <p class="left-heading-item">Team count</p>
          <p class="left-heading-item">Lead contact</p>
          <!-- <p class="left-heading-item">Monthly Value</p>
          <p class="left-heading-item">Total Sales to Date</p> -->
        </template>
        <template #right-headings>
          <p class="right-heading-item">Status</p>
        </template>

        <TableTile v-for="studio of studios" :key="studio.id">
          <template #tileContent>
            <router-link
              class="left-border-active custom-class-margin"
              :to="{ name: 'Overview', params: { studioId: studio.id } }"
            >
              <div class="left-content">
                <div>{{ studio.name }}</div>
                <div>{{ dateFormatter(studio.createdAt) }}</div>
                <div>{{ studio.user.length }}</div>
                <div>23/08/21</div>
                <!-- <div>3 days ago</div>
                <div>3 days ago</div> -->
              </div>
              <div class="right-content">
                <StatusIndicator class="margin-right" color="green" text="Active" />
              </div>
            </router-link>
          </template>
          <template #menu>
            <ul>
              <li @click="deleteStudio(studio.id)">Delete</li>
            </ul>
          </template>
        </TableTile>
      </TableLayout>
    </template></DashboardSection
  >
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import OverviewBlock from '@/components/atom/OverviewBlock.vue'
import TableTile from '@/components/templates/TableTile.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import TableLayout from '@/components/templates/TableLayout.vue'
import StatusIndicator from '@/components/atom/StatusIndicator.vue'
import authApi from '@/api/Authenticated'
import useDateFormatter from '@/utils/dateFormatter'
import type { studioTypes } from '@shared/'
import VueSimpleAlert from 'vue-simple-alert'
import { ActionTypes } from '@/store/messages/actions'
import { MutationTypes } from '@/store/messages/mutations'
import { useStore } from '@/store'

export default defineComponent({
  name: 'ProjectDashboard',
  components: {
    OverviewBlock,
    DashboardSection,
    TableTile,
    CustomButton,
    TableLayout,
    StatusIndicator
  },
  setup() {
    const store = useStore()
    const studios = ref<studioTypes.StudioWithDetails[]>([])
    const { dateFormatter } = useDateFormatter()
    async function getStudioDetails() {
      const res = await authApi.getAllStudios()
      studios.value = res
    }
    onMounted(getStudioDetails)

    const deleteStudio = async (studioId: number) => {
      VueSimpleAlert.confirm(
        'All data recorded in relation to this Studio will be deleted. Please confirm to continue.'
      ).then(async (res) => {
        if (res) {
          await authApi
            .deleteStudio(+studioId)
            .then(function () {
              const index = studios.value.findIndex((e) => e.id === studioId)
              if (index !== -1) {
                studios.value.splice(index, 1)
              }
            })
            .catch((error) => {
              store.commit(MutationTypes.SET_TOASTER_MESSAGE, error.response.data)
              store.dispatch(ActionTypes.SET_TOASTER_TYPE, 'error')
              store.dispatch(ActionTypes.SHOW_TOASTER_MESSAGE, true)
            })
        }
      })
    }

    return { studios, dateFormatter, deleteStudio }
  }
})
</script>

<style lang="sass" scoped>
.block-wrapper
  width: 100%
  display: grid
  grid-template-columns: repeat(6, 1fr)
  justify-content: space-between
  grid-gap: 1rem
  @media screen and (max-width: $ms-breakpoint)
    grid-template-columns: 1fr
  .subtext
    color: $text-grey
    margin: 0.5rem
  h3
    margin-left: 30px
  .small
    width: 130px
    margin-bottom: 20px
</style>

<style lang="sass" scoped>
$leftWidth: 8rem
$rightWidth: 5rem
.left-content, .right-content
  display: flex
  align-items: center
.left-content > *
  width: $leftWidth
.right-content > *
  min-width: $rightWidth
.right-heading-item
  min-width: $rightWidth
.left-heading-item
  min-width: $leftWidth
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
.mobile-hide
  @media( max-width: $md-breakpoint)
    display: none
.left-border-active
  border-left: 4px solid
  border-radius: 4px
.mobile-view
  display: none
  @media( max-width: $md-breakpoint)
    display: block
.custom-class-margin
  margin-bottom: unset
  border-bottom: 0.1px solid rgba(0, 0, 0, 0.1)
.margin-right
  margin-right: 0.5rem
</style>
